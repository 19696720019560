import "./Login.css";
import Tim from "../../asset/TIM_Logo.png";
import { useLogin } from "./useLogin";
import { Redirect } from "react-router-dom";

const Login = () => {
  const { setValueUsername, setValuePassword, handleLogin, statusCode } =
    useLogin();
  return (
    <>
      {statusCode === 200 ? (
        <Redirect push to="/dashboard" />
      ) : (
        <div className="container">
          <div className="flex_middle border_bottom">
            <img src={Tim} />
          </div>
          <div className="flex_middle flex_column">
            <div>
              <p style={{ margin: "50px 0 4px 0" }}>Username :</p>
              <input
                type="text"
                className="input_login"
                onChange={(e) => {
                  setValueUsername(e.target.value);
                }}
              />
            </div>
            <div>
              <p style={{ margin: "30px 0 4px 0" }}>Password :</p>
              <input
                type="password"
                className="input_login"
                onChange={(e) => setValuePassword(e.target.value)}
              />
            </div>
            <div className="login_btn" onClick={() => handleLogin()}>
              Login
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Login;
