import { useState, useEffect } from "react";
import Search from "../../component/search";
import AccordionEditUser from "../../component/AccordionEditUser/AccordionEditUser";
import axios from "axios";
import * as XLSX from "xlsx";

const EditUser = () => {
  const getToken = localStorage.getItem("auth")
    ? localStorage.getItem("auth")
    : null;
  const getTim = localStorage.getItem("user");
  const jsonRole = JSON.parse(getTim);
  const jsonTim = JSON.parse(getTim);

  const [statusCode, setStatusCode] = useState();
  const [dataUser, setDataUser] = useState();
  const [popUp, setPopUp] = useState(false);
  const [popUpImport, setPopUpImport] = useState(false);
  const [peopleInfo, setPeopleInfo] = useState([]);
  const [peopleInfoAll, setPeopleInfoAll] = useState(false);

  const [file, setFile] = useState(null);
  const [isReload, setIsReload] = useState(false);

  const sortByName = (data) =>
    data.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  const getTableData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/userList`,
        { type: "editUser", tim: jsonTim.tim },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.data.code === 404) {
        setStatusCode(
          response.data.code !== undefined ? response.data.code : ""
        );
      } else {
        let result1 = [],
          result2 = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].probation === true) {
            result1.push(response.data[i]);
          } else {
            result2.push(response.data[i]);
          }
        }
        result2.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        let result = result1.concat(result2);
        setDataUser(result);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getTableData();
    setIsReload(false);
  }, [isReload]);

  const [paramSearch, setParamSearch] = useState("nameId");
  const [valueSearch, setValueSearch] = useState("");
  const [editType, setEditType] = useState("Data Diri");

  const searchFeature = (params) => {
    if (paramSearch === "nameId" && valueSearch !== "") {
      let searchName = [];
      dataUser &&
        dataUser.forEach((value) => {
          if (value.name.toLowerCase().includes(params.toLowerCase())) {
            searchName.push(value);
          }
          if (value.id.includes(params)) {
            searchName.push(value);
          }
        });
      return searchName;
    } else {
      return dataUser;
    }
  };

  let dataTemp = searchFeature(valueSearch);

  const formatDot = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const exportGaji = () => {
    const d = new Date();
    const year = d.getFullYear();
    const dataObject = dataUser.map((obj) => {
      return {
        rekening: obj.bankAccountNumber,
        ktp: obj.id,
        nama: obj.name,
        bagian: obj.bagian,
        harian: Math.round(obj.basicSallary / 30),
        gaji: obj.basicSallary,
        uangMakan: obj.uangMakan,
        uangTransport: obj.uangTransport,
        bpjsK: obj.bpjsK,
        bpjsTK: obj.bpjsTK,
      };
    });

    const sortByNama = dataObject.sort(function (a, b) {
      if (a.Nama < b.Nama) {
        return -1;
      }
      if (a.Nama > b.Nama) {
        return 1;
      }
      return 0;
    });

    const workSheet = XLSX.utils.json_to_sheet(sortByNama);
    workSheet["!cols"] = [
      { wch: 15 },
      { wch: 18 },
      { wch: 35 },
      { wch: 17 },
      { wch: 13 },
      { wch: 13 },
      { wch: 13 },
      { wch: 14 },
      { wch: 10 },
      { wch: 10 },
    ];
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Data Gaji");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, `Data Gaji Karyawan ${year}.xlsx`);
  };

  const exportDataDiri = () => {
    const d = new Date();
    const year = d.getFullYear();
    const dataObject = dataUser.map((obj) => {
      return {
        "No.Ktp": obj.id,
        Nama: obj.name,
        "No.NPWP": obj.npwpNumber,
        "No.Rekening": obj.bankAccountNumber,
        Email: obj.email,
        "No.Telepon": obj.phoneNumber,
        Alamat: obj.alamat,
        Bagian: obj.bagian,
        "K.A Shift": obj.kepalaShift,
        "Status Perkawinan": obj.statusPerkawinan,
        "Jumlah Anak": obj.jumlahAnak,
        "Tempat Tanggal Lahir": obj.tempatTanggalLahir,
        Posisi: obj.position,
        "Kontrak Awal": obj.kontrakAwal,
      };
    });

    const sortByNama = dataObject.sort(function (a, b) {
      if (a.Nama < b.Nama) {
        return -1;
      }
      if (a.Nama > b.Nama) {
        return 1;
      }
      return 0;
    });

    const workSheet = XLSX.utils.json_to_sheet(sortByNama);
    workSheet["!cols"] = [
      { wch: 17 },
      { wch: 27 },
      { wch: 20 },
      { wch: 15 },
      { wch: 30 },
      { wch: 15 },
      { wch: 35 },
      { wch: 20 },
      { wch: 11 },
      { wch: 17 },
      { wch: 12 },
      { wch: 35 },
      { wch: 15 },
      { wch: 17 },
    ];

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Data Gaji");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, `Data Diri Karyawan ${year}.xlsx`);
  };

  const handleTambahHari = async () => {
    setPopUp(false);
    try {
      await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/tambahHari`,
        {
          tim: jsonTim.tim,
          tambahAll: peopleInfoAll,
          tambahBeberapa: peopleInfo,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
    } catch (error) {
      return error;
    }
  };

  const handleConvert = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/importDataUser`,
          { data: json },
          {
            headers: {
              Authorization: `Bearer ${getToken}`,
            },
          }
        );
      };
      reader.readAsBinaryString(file);
      setPopUpImport(true);
    }
    setIsReload(true);
    setValueSearch("");
  };

  return (
    <div>
      {popUpImport ? (
        <div className="popup_container">
          <div className="popup_wrapper">
            <p style={{ lineHeight: "20px", fontSize: "16px" }}>
              Selamat! Data kamu sudah di Update
            </p>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className="reset_button"
                style={{ background: "red" }}
                onClick={() => setPopUpImport(false)}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {popUp ? (
        <div className="popup_container">
          <div className="popup_wrapper">
            <p style={{ lineHeight: "20px", fontSize: "14px" }}>
              Apakah Kamu yakin untuk menambahkan 1 hari kerja ke seluruh
              karyawan yang <b>AKTIF</b> ?
            </p>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button className="cancel_button" onClick={() => setPopUp(false)}>
                Cancel
              </button>
              <button
                className="reset_button"
                style={{ background: "red" }}
                onClick={() => handleTambahHari()}
              >
                Tambah
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "12px 12px 0 0",
        }}
      >
        <input
          type="file"
          accept=".xls,.xlsx"
          onChange={(e) => setFile(e.target.files[0])}
          style={{ cursor: "pointer", width: "25%" }}
        />
        <button
          style={{
            cursor: "pointer",
            padding: "12px",
            cursor: "pointer",
            background: "gold",
            border: "none",
            borderRadius: "6px",
            fontWeight: "bold",
          }}
          onClick={handleConvert}
        >
          IMPORT
        </button>
      </div>
      <p style={{ fontWeight: "bold", marginTop: 0 }}>Edit User</p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <Search valueSearch={(e) => setValueSearch(e.target.value)} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0 16px 24px 0",
            }}
            onClick={() => setEditType("Data Diri")}
          >
            <input
              type="radio"
              value="Data Diri"
              checked={editType === "Data Diri"}
            />
            <label style={{ fontSize: "14px" }}>Data Diri</label>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0 16px 24px 0",
            }}
            onClick={() => setEditType("Data Keuangan")}
          >
            <input
              type="radio"
              value="Data Keuangan"
              checked={editType === "Data Keuangan"}
            />
            <label style={{ fontSize: "14px" }}>Data Keuangan</label>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {jsonRole.role === "hrd_admin" || jsonRole.role === "super_user" ? (
            <div className="reset_data_button" onClick={() => setPopUp(true)}>
              Tambah Hari
            </div>
          ) : (
            ""
          )}
          <div
            className="export_btn"
            style={{ marginRight: "12px" }}
            onClick={() => exportGaji()}
          >
            Export Gaji
          </div>
          <div
            className="export_btn"
            style={{ marginRight: "12px" }}
            onClick={() => exportDataDiri()}
          >
            Export Data Diri
          </div>
        </div>
      </div>
      {jsonRole.role === "hrd_admin" || jsonRole.role === "super_user" ? (
        <th style={{ border: "none", padding: "12px 0px 12px 2px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label style={{ textAlign: "center" }}>All</label>
            <input
              checked={peopleInfoAll}
              style={{ height: "18px", width: "18px" }}
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  setPeopleInfoAll(true);
                } else {
                  setPeopleInfoAll(false);
                }
              }}
            />
          </div>
        </th>
      ) : (
        ""
      )}

      <div
        style={{
          borderBottom: "1px solid lightgrey",
          fontWeight: "bold",
          fontSize: "18px",
        }}
      />
      <div style={{ width: "0px", minWidth: "100%", overflow: "auto" }}>
        <table>
          {dataTemp &&
            dataTemp.map((data, index) => {
              return (
                <AccordionEditUser
                  data={data}
                  editType={editType}
                  index={index}
                  peopleInfo={peopleInfo}
                  setPeopleInfo={setPeopleInfo}
                />
              );
            })}
        </table>
      </div>
    </div>
  );
};
export default EditUser;
